import React from "react";
import styles from "./HeaderMain.module.css";
import burger from "../images/burger__menu.png";
import { FC, useMemo } from "react";
import { Link as ScrollLink } from "react-scroll";
import { slide as Menu } from "react-burger-menu";
import { data } from "../../constants/constants";
const HeaderMain = () => {
  const renderTitle = useMemo(
    () => data.map((item) => <a href={item.links}> {item.title}</a>),
    [data]
  );
  const CustomBurgerIcon = () => (
    <div className="burgermenu">
      <img
        style={{
          width: "32px",
        }}
        src={burger}
      />
    </div>
  );
  return (
    <div>
      <section className={styles.main}>
        <header>
          <div className={styles.container2}>
            <a href="/">
              <h6>AllWatch</h6>
            </a>
            <div className={styles.nav2}>
              <a className={styles.header__link} href="/catalog">
                Каталог
              </a>
              <a className={styles.header__link} href="/contact">
                Контакты
              </a>
              <a className={styles.header__link} href="/delivery">
                Доставка
              </a>
            </div>
            <div className={styles.Burger}>
              <Menu
                customBurgerIcon={<CustomBurgerIcon />}
                isOpen={false}
                width={"100px"}
                right
              >
                <nav>{renderTitle}</nav>
              </Menu>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};

export default HeaderMain;
