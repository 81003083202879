import React, { useState } from "react";
import styles from "./DeliveryPage.module.scss";
import Headermain from "../components/header-main/Header-main";
import FooterMain from "../components/footermain/FooterMain";
import Main from "./Main";
import MainPage from "../components/main/main";
import HeaderMain from "../components/HeaderMain/HeaderMain";
const DeliveryPage = () => {
  return (
    <>
      <HeaderMain />
      <div className={styles.delivery}>
        <div className={styles.order}>
          <h1>Доставка</h1>
          ⬇️
          <p>Доставка осуществляется в течении рабочего дня маркетплейса или же на следующий день</p>
        </div>
        <ul>
          <li>1 шаг </li>
          <li>Клиент связывается с нами, либо же заполняет форму заказа </li>
          ⬇️
          <li>2 шаг</li>
          <li>
            После договоренности с клиентом наш менеджер оформляет заказ и
            готовит товар для отправки клиенту через службы курьерской доставки
          </li>
          ⬇️
          <li>3 шаг</li>
          <li>
            Даем гарантию на получение часов и обеспечение сохранности товара
          </li>
          ⬇️
          <li>4 шаг </li>
          <li>
            Доставка по городу Бишкек осуществляется бесплатно,компания сама
            оплачивает расходы на доставку часов
          </li>
          ⬇️
          <li>5 шаг </li>
          <li>
            Доставка в регионы осуществляется способом доставки по усмотрению
            клиента, Сдек,Ылдам экспресс и.т.д
          </li>
        </ul>
        <div className={styles.details}>
          <h1>Дополнительно</h1>
          ⬇️
          <ul>
            <li>
              Занимаемся покупкой часов из Америки,Китая, в случае если не нашли
              желаемый товар на нашем сайте или в городе Бишкек
            </li>
          </ul>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default DeliveryPage;
