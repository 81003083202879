import React from 'react'
import img2 from "../images/casio-logo.jpg"
import img3 from "../images/tissot-logo-0.png"
import img4 from "../images/R.jpg"
import img5 from "../images/R.png"
const New = () => {
  return (
    <section class="brands">
    <div class="container">
      <h1>Бренды</h1>
      <hr  />
      <div class="brands__cards">
        <div class="brand">
          <a href="#"
            ><img src={img2}alt="brand__logo"
          /></a>
        </div>
        <div class="brand">
          <a href="#"
            ><img src={img3} alt="brand__logo"
          /></a>
        </div>
        <div class="brand">
          <a href="#"
            ><img src={img4} alt="brand__logo"
          /></a>
        </div>
        <div class="brand">
          <a href="#"
            ><img src={img5} alt="brand__logo"
          /></a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default New
