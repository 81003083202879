import React from "react";
import styles from "./App.css"
import { Context } from "./context/context.js";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage.js";
import { ProductOverView } from "./pages/ProductOverView.js";
import { BtnScroll } from "./components/btn-scroll/BtnScroll.js";
import Main from "./pages/Main.js";
import ContactForm from "./components/modalOrder/ContactForm.js"
import DeliveryPage from "./pages/Delivery.js";
import AddProduct from "./components/AddProducts/AddProduct.js";
import Contact from "./pages/Contact.js";



export default function App() {
  const productId = { id: 0 };

  return (
    <div className={styles.App}>
      <Context.Provider value={productId}>
        <Routes>
          <Route path="/catalog" element={<HomePage />} />
          <Route path="/" element={<Main />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products/:id" element={<ProductOverView />} />
          <Route path="/add-products" element={<AddProduct />} />
        </Routes>
        <BtnScroll />
      </Context.Provider>
    </div>
  );
}
