import React, { useState } from "react"
import { useGetProductsAllQuery } from "../redux/products/products.api"
import { ProductItem } from "../components/product-item/ProductItem"
import { Loader } from "../components/loader/Loader"
import { ProductFilters } from "../components/product-filters/ProductFilters"
import { useSelector } from "react-redux"
import { Header } from "../components/header/Header"
import FooterMain from "../components/footermain/FooterMain"


export function HomePage() {
    const { data, error, isLoading, status } = useGetProductsAllQuery()
    const { filters, searchFilterProducts } = useSelector(state => state.product)
    
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    const filterByCategory = (category) => {
        return data?.filter(product => product.category === category)
            .map(product => <ProductItem product={product} key={product.id} />)
    }

    const filterByPrice = price => {
        const products = data?.filter(item => item.price >= price[0] && item.price <= price[1])
            .map(product => <ProductItem product={product} key={product.id} />);
    
        if (!products || products.length === 0) {
            return (
                <div className='text-center'>
                    <p className='font-medium'>Products not found...</p>
                    <p>No products available within the specified price range.</p>
                </div>
            );
        }
    
        return products;
    };

    const filterByRating = rating => {
        return data?.filter(item => rating === 'above' ? item.rating.rate >= 3 : item.rating.rate <= 3)
            .map(product => <ProductItem product={product} key={product.id} />)
    }

    const filterSearch = data => {
        if (data.length > 0) {
            return data.map(product => <ProductItem product={product} key={product.id} />)
        } else {
            return <p className='mt-[30px] mx-auto'>Products not found...</p>
        }
    }

    const renderProducts = () => {
        let filteredData = data;
        if (filters.category) filteredData = filteredData?.filter(product => product.category === filters.category)
        if (filters.price) filteredData = filteredData?.filter(item => item.price >= filters.price[0] && item.price <= filters.price[1])
        if (filters.rating) filteredData = filteredData?.filter(item => filters.rating === 'above' ? item.rating.rate >= 3 : item.rating.rate <= 3)
        if (searchFilterProducts) filteredData = searchFilterProducts

        const startIndex = (currentPage - 1) * itemsPerPage;
        const paginatedData = filteredData?.slice(startIndex, startIndex + itemsPerPage);

        return paginatedData?.map(product => <ProductItem product={product} key={product.id} />)
    }

    const totalPages = Math.ceil((data?.length || 0) / itemsPerPage);

    const getPaginationButtons = () => {
        const buttons = [];
        if (totalPages <= 8) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(i);
            }
        } else {
            buttons.push(1, 2);
            if (currentPage > 4) {
                buttons.push('...');
            }
            const startPage = Math.max(3, currentPage - 2);
            const endPage = Math.min(totalPages - 2, currentPage + 2);
            for (let i = startPage; i <= endPage; i++) {
                buttons.push(i);
            }
            if (currentPage < totalPages - 3) {
                buttons.push('...');
            }
            buttons.push(totalPages - 1, totalPages);
        }
        return buttons;
    }

    return (
        <>
            <Header />
            <div className='container mb-[20px] mt-[60px] max-w-[1240px] overflow-x-hidden overflow-y-hidden flex flex-col justify-center items-center xl:flex xl:justify-center xl:flex-row xl:items-start mx-auto transition-all flex-auto'>
                {isLoading && <Loader />}
                {error && <p className='text-center mt-4'>{error.error}</p>}
                {status === 'fulfilled' &&
                    <>
                        <div className='lg:w-3/5 xl:w-1/5 flex flex-col items-center mt-[10px]'>
                            <ProductFilters />
                        </div>
                        <div className='w-4/5 overflow-hidden flex flex-wrap justify-center md:w-full xl:justify-start xl:ml-[20px]'>
                            {renderProducts()}
                        </div>
                    </>
                }
            
            </div>
            <div className='pagination mt-4'>
                    {getPaginationButtons().map((page, index) => (
                        <button
                            key={index}
                            className={`pagination-btn ${currentPage === page ? 'active' : ''}`}
                            onClick={() => typeof page === 'number' && setCurrentPage(page)}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            <FooterMain />
        </>
    )
}
