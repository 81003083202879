import React, { useState } from 'react';
import axios from 'axios';
import ProductList from '../ProductList/ProductList';
import styles from './AddProduct.module.scss';

const AddProduct = () => {
  const [product, setProduct] = useState({
    id: '', // Initialize id with an empty string or a unique value
    title: '',
    price: '',
    description: '',
    category: '',
    image: '',
    meha: '',
    country: '',
    water: '',
    material: ''
  });

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://jsonnew-91377f8011d0.herokuapp.com/products', product)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('There was an error adding the product!', error);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form3}>
        <div className={styles.column}>
          <label>Id</label>
          <input type="text" name="id" value={product.id} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Title</label>
          <input type="text" name="title" value={product.title} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Price</label>
          <input type="number" name="price" value={product.price} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Description</label>
          <input type="text" name="description" value={product.description} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Category</label>
          <input type="text" name="category" value={product.category} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Image URL</label>
          <input type="text" name="image" value={product.image} onChange={handleChange} />
        </div  >
        <div className={styles.column}>
          <label>Meha</label>
          <input type="text" name="meha" value={product.meha} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Country</label>
          <input type="text" name="country" value={product.country} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Water Resistance</label>
          <input type="text" name="water" value={product.water} onChange={handleChange} />
        </div>
        <div className={styles.column}>
          <label>Material</label>
          <input type="text" name="material" value={product.material} onChange={handleChange} />
        </div>
        <button className={styles.add}  type="submit">Add Product</button>
      </form>
      <ProductList />
    </>
  );
};

export default AddProduct;
