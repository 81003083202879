import React from "react";
import styles from "./FooterMain.module.scss";
import DeliveryPage from "./../../pages/Delivery";
import tg from "../../assets/icons8-tg-48.png";
import inst from "../../assets/icons8-instagram-48.png";
import whats from "../../assets/icons8-whatsapp-48.png";
const FooterMain = () => {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.about__store}>
          <h1 className={styles.aboutus__title}>О магазине</h1>
          <p className={styles.aboutus__txt}>
            Мы верим в силу времени и истории, которые оно рассказывает. Наша
            миссия, основанная в году, заключалась в том, чтобы предоставить
            энтузиастам и коллекционерам по всему миру лучший выбор часов. Со
            страстью к часовому делу и приверженностью качеству мы создаем
            коллекции, сочетающие в себе элегантность, функциональность и
            инновации.
          </p>
        </div>
        <div className={styles.categories}>
          <h1 className={styles.aboutus__title}>Категории</h1>
          <div className={styles.choose}>
            <a href="#">часы</a>
            <a href="#">браслеты</a>
            <a href="#">ремни</a>
            <a href="#">ювелирные изделия</a>
            <a href="#">запонки</a>
          </div>
        </div>
        <div className={styles.newsletter}>
          <h1 className={styles.aboutus__title}>Рассылка</h1>
          <div className={styles.flex__content}>
          <div className={styles.whats}>
            <a href="https://wa.me/+996509435819">
              <img src={whats} />
            </a>
          </div>
          <div className={styles.tg}>
            <a href="https://t.me/AllWatchMarketplace">
              <img src={tg} />
            </a>
          </div>
          <div className={styles.inst}>
            <a href="https://www.instagram.com/all_watch.kg/?next=%2F">
              <img src={inst} />
            </a>
          </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;
