import React from "react";
import "./season.css";

import img2 from "../images/R (2).jpg";
import img3 from "../images/R (3).jpg";
const Season = () => {
  return (
    <section className="season">
      <div className="top">
        <div className="left">
          <h1>СЕЗОН 2024/25</h1>
          <div id="season__slider">
            <div className="clock">
              <div className="clock__img">
                <img src="https://i.ebayimg.com/images/g/GWQAAOSw5kVjc7vf/s-l1600.webp" alt="clock__icon" />
              </div>
              <p>New Tissot Seastar 1000</p>
              <p>45000сом</p>
            </div>
            <div className="clock">
              <div className="clock__img">
                <img src="https://i.ebayimg.com/images/g/O5wAAOSwrEtmTw5V/s-l1600.webp" alt="clock__icon" />
              </div>
              <p>Casio G-SHOCK</p>
              <p>35000сом</p>
            </div>
            <div className="clock">
              <div className="clock__img">
                <img src="https://i.ebayimg.com/images/g/B4cAAOSwUX1ki2sG/s-l1600.webp" alt="clock__icon" />
              </div>
              <p>Tissot</p>
              <p>58000сом</p>
            </div>
          </div>
        </div>
        <div className="right">
          <h1>Новая коллекция</h1>
          <hr />
          <button > <a href="/catalog">Каталог</a></button>
        </div>
      </div>
      <div className="bottom">
        <div className="left"></div>
        <div className="right">
          <h1>коллекция 2024</h1>
          <hr />
          <p>
            Вступая в 2024 год, All Watch с гордостью представляет нашу
            последнюю коллекцию, в которой инновации сочетаются с вечной
            элегантностью. В этом сезоне мы подготовили подборку часов,
            отражающих последние тенденции, передовые технологии и классический
            дизайн. Откройте для себя идеальные часы, которые дополнят ваш стиль
            и украсят ваш гардероб.
          </p>
          <button ><a href="/catalog">посмотреть коллекцию</a></button>
        </div>
      </div>
    </section>
  );
};

export default Season;
