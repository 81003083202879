import React from "react";
import Headermain from "../components/header-main/Header-main";
import MainPage from "../components/main/main";
import styles from "./MainModule.module.css"
import Season from "../components/season/season";
import New from "../components/new/new";
import FooterMain from "../components/footermain/FooterMain";
const Main = () => {
  return (
    <div className={styles.div}>
      <Headermain />
      <MainPage/>
      <Season/>
      <New/>
      <FooterMain/>
    </div>
  );
};

export default Main;
