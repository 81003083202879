export const optionsProducts = {
    brands: [
        {content: 'samsung', selectedClass: 'ring-indigo-600'},
        {content: 'asus', selectedClass: 'ring-indigo-600'},
        {content: 'lg', selectedClass: 'ring-indigo-600'},
        {content: 'apple', selectedClass: 'ring-indigo-600'},
        {content: 'xiaomi', selectedClass: 'ring-indigo-600'},
        {content: 'lenovo', selectedClass: 'ring-indigo-600'}
    ]
}

export const inputsCategory = [
    {id: "women's watch", textContext: "Женские часы"},
    {id: "Japan watch", textContext: "Японские часы"},
    {id: "Swiss watch", textContext: "Швейцарские часы"},
    {id: "Amerika watch", textContext: "Американские часы"}
]

export const inputsRating = [
    {
        type: 'checkbox',
        id: 'above',
        classInput: 'w-[15px]',
        classDiv: 'flex',
        classLabel: 'pl-1 cursor-pointer',
        textContent: 'Above average'
    },
    {
        type: 'checkbox',
        id: 'below',
        classInput: 'w-[15px]',
        classDiv: 'flex mt-2',
        classLabel: 'pl-1 cursor-pointer',
        textContent: 'Below average'
    }
]

export const data = [
    { title: "Главная", links: "/" },
    { title: "Каталог", links: "/catalog" },
    { title: "Доставка", links: "/delivery" },
    { title: "Контакты", links: "/Contact" },
  
  ];
  