import React from 'react'
import styles from "./Contact.module.scss"
import ContactForm from '../components/modalOrder/ContactForm'
import FooterMain from '../components/footermain/FooterMain'
import { Header } from '../components/header/Header'
import HeaderMain from '../components/HeaderMain/HeaderMain'
const Contact = () => {
  return (
    <div className={styles.contact}>
      <HeaderMain/>
      <ContactForm/>
      <FooterMain/>
    </div>
  )
}

export default Contact
