import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './ProductList.module.scss';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://jsonnew-91377f8011d0.herokuapp.com/products')
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        setError('There was an error fetching the products!');
        console.error('Fetch error:', error);
      });
  }, []);

  const handleDelete = (id) => {
    axios.delete(`https://jsonnew-91377f8011d0.herokuapp.com/products/${id}`)
      .then(response => {
        setProducts(products.filter(product => product.id !== id));
      })
      .catch(error => {
        setError('There was an error deleting the product!');
        console.error('Delete error:', error);
      });
  };

  return (
    <div className={styles.ProductList}>
      <h1>Product List</h1>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.flex5}>
        <ul>
          {products.map(product => (
            <li key={product.id}>
              <p>{product.id}</p>
              <img src={product.image} alt={product.title} />
              <p>{product.title}</p>
              <button className={styles.delete} onClick={() => handleDelete(product.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductList;
