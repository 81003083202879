import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useGetProductByCategoryQuery } from "../../redux/products/products.api";
import {
  faCartShopping,
  faCheck,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../redux/products/products.slice";
import { Context } from "../../context/context";
import { Colors } from "../optionsProduct/Colors";
import { Sizes } from "../optionsProduct/Sizes";
import { Brands } from "../optionsProduct/Brands";
import styles from "./product.module.scss"
import { Header } from "../header/Header";


export function ProductDetailItem({ product, refetch, status }) {
  const contextId = useContext(Context);
  const { cart } = useSelector((state) => state.product);

  const { data, isLoading, error } = useGetProductByCategoryQuery(
    product.category
  );
  const filterProductFooter = data?.filter((item) => item.id !== product.id);

  const isCart = !!cart.find((el) => el.id === product.id);
  const dispatch = useDispatch();

  const addProduct = () => dispatch(addProductToCart(product));

  const countStar = (rating) => {
    const arr = [];
    const ratingRound = Math.round(rating);
    for (let i = 0; i < ratingRound; i++) {
      arr.push(i);
    }
    return arr.map((star) => (
      <FontAwesomeIcon key={star} className="text-yellow-500" icon={faStar} />
    ));
  };

  const optionsProduct = (category) => {
    if (category === "men's watch" || category === "women's watch")
      return <Colors />;
    if (category === "swiss") return <Sizes />;
    if (category === "japan") return <Sizes />;
    if (category === "china") return <Brands />;
  };

  const clickHandler = (productId) => {
    contextId.id = productId;
    refetch();
    if (status === "fulfilled") {
      const body =
        document.querySelector("body").getBoundingClientRect().top +
        window.scrollY;
      window.scroll({
        top: body,
        behavior: "smooth",
      });
    }
  };

  return (
    <>

      <div className="px-2 pb-6">
        <p className="capitalize text-gray-500 mb-6 text-sm font-semibold sm:text-base">
          <Link
            to="/catalog"
            className="transition hover:border-b-2 border-b-gray-500"
          >
            Каталог
          </Link>
          <span className="px-2">\</span>
          {product.category}
          <span className="pl-2">\</span>
        </p>
        <div className="flex flex-col justify-center items-center lg:flex lg:flex-row lg:justify-between lg:items-start">
          <div className="w-full shadow-lg rounded-md flex justify-center items-center lg:w-2/4 sm:w-3/4">
            <img
              className="w-2/4 lg:w-3/4"
              src={product.image}
              alt={product.title}
            />
          </div>
          <div className="w-full pl-0 sm:w-3/4 lg:w-2/4 lg:pl-[30px]">
            <div>
              <p className="font-medium text-xl mt-5 lg:mt-0 md:text-2xl">
                {product.title}
              </p>
              <p className="my-4 text-xl sm:text-2xl">{product.price}</p>
              <div className="flex justify-start">
                <div className="mr-4"></div>
              </div>
              <p className="text-base mt-4 sm:text-lg">{product.description}</p>
              <ul className="">
                <div className={styles.characteristick}>
                  <p>Механизм:</p>
                  <li>{product.meha}</li>
                </div>
                <div className={styles.characteristick}>
                  <p>Cтрана:</p>
                  <li>{product.country}</li>
                </div>
                <div className={styles.characteristick}>
                <p>Водозащита:</p>
                  <li>{product.water}</li>
                </div>
                <div className={styles.characteristick}>
                <p>Материал:</p>
                  <li>{product.material}</li>
                </div>
              </ul>
              <div className="my-4"></div>
              <button
                onClick={addProduct}
                disabled={isCart}
                className={`w-full bg-black mt-8 text-md rounded-md text-white transition px-[80px] py-[15px] sm:text-lg md:w-fit ${
                  isCart ? "border px-[40px] py-[10px]" : "hover:bg-gray-800"
                }`}
              >
                {isCart ? (
                  <FontAwesomeIcon
                    className="text-[25px] sm:text-[30px]"
                    icon={faCheck}
                  />
                ) : (
                  <>
                  В корзину
                    <span className="ml-2">
                      <FontAwesomeIcon icon={faCartShopping} />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
