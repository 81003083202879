import React from 'react'
import styles from "./head.module.css"
import phone from "../images/eva_phone-outline.png"
import burger from "../images/burger__menu.png"
const Headermain = () => {
  
  return (
    <div >
          <div class={styles.contact}>
      <div class={styles.container}>
        <div class={styles.left__content}>
          <a class={styles.contact__link} href="+996703131589"
            ><img src={phone} alt="phone__icon" /> +996
            509435819</a>
          <div class={styles.line}></div>
          <p class={styles.contact__txt}>Работаем 7 дней в неделю</p>
          <div class={styles.line}></div>
          <p class={styles.contact__txt}>9:00 - 18:00</p>
        </div>   
      </div>
    </div>
    </div>
  )
}

export default Headermain
