import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productsApi = createApi({
    reducerPath: 'products/api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://jsonnew-91377f8011d0.herokuapp.com',
        prepareHeaders: (headers) => {
            console.log('Headers:', headers);
            return headers;
        },
    }),
    endpoints: (build) => ({
        getProductsAll: build.query({
            query: () => {
                console.log('Fetching all products...');
                return { url: '/products' };
            }
        }),
        getProductById: build.query({
            query: (id) => {
                console.log(`Fetching product by id: ${id}`);
                return { url: `/products/${id}` };
            }
        }),
        getProductByCategory: build.query({
            query: (category) => {
                console.log(`Fetching products by category: ${category}`);
                return { url: `/products/category/${category}` };
            }
        })
    })
});

export const {
    useGetProductsAllQuery,
    useGetProductByIdQuery,
    useGetProductByCategoryQuery
} = productsApi;
