import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ContactForm.module.scss";
import emailjs from "emailjs-com";
import succesfull from "../../assets/Vector.svg";
import { useDispatch } from "react-redux";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [text, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastError, setLastError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    let isValid = true;

    if (!isCheckboxChecked) {
      setCheckboxError("You must agree to the terms.");
      isValid = false;
    } else {
      setCheckboxError("");
    }

    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (text.trim() === "") {
      setLastError("Last name is required");
      isValid = false;
    } else {
      setLastError("");
    }

    if (password.trim() === "") {
      setPasswordError("Phone number is required");
      isValid = false;
    } else if (!/^\d+$/.test(password)) {
      setPasswordError("Phone number should contain only numbers.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      isValid = false;
    } else if (!email.includes("@")) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (message.trim() === "") {
      setMessageError("Message is required");
      isValid = false;
    } else {
      setMessageError("");
    }

    if (!isValid) {
      setButtonDisabled(false);
      return;
    }

    const serviceId = "service_ec0nuv9";
    const templateId = "template_e2da9od";
    const userId = "ae5-bU9qaERVPbxVT";

    const templateParams = {
      name,
      email,
      message,
      text,
      password,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        setLastName("");
        setPassword("");
        setIsFormSubmitted(true);
        setButtonDisabled(false);
      })
      .catch((error) => {
        console.error("Error sending email", error);
        setButtonDisabled(false);
      });
  };

  return (
    <div className={styles.ContactForm}>
      {isFormSubmitted ? (
        <div className={styles.successMessage}>
          <div className={styles.succesfull}>
            <img src={succesfull} alt="Success" />
            <p>Форма отправлена успешно</p>
          </div>
          <button
            onClick={() => {
              setIsFormSubmitted(false);
            }}
          >
            Перезагрузить
          </button>
        </div>
      ) : (
        <div style={{ width: "90%" }} className="container">
          <button className={styles.closeButton} onClick={() => navigate("/catalog")}>
          ✖
          </button>
          <form onSubmit={handleFormSubmit}>
            <div id="contact" className={styles.Paragraph}>
              <h1>Оформить заказ</h1>
            </div>
            <input
              placeholder="Имя"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && <span className={styles.error}>{nameError}</span>}
            <input
              placeholder="Фамилия"
              type="text"
              value={text}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastError && <span className={styles.error}>{lastError}</span>}
            <input
              placeholder="Номер телефона"
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <span className={styles.error}>{passwordError}</span>}
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <span className={styles.error}>{emailError}</span>}
            <div className={styles.form__message}>
              <input
                className={styles.input__message}
                placeholder="Напишите сообщение"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            {messageError && <span className={styles.error}>{messageError}</span>}
            <div className={styles.contact__agree}>
              <input
                type="checkbox"
                name="checkbox"
                id="check"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <p>
                Нажимая кнопку отправить вы соглашаетесь на обработку ваших данных
              </p>
            </div>
            {checkboxError && <span className={styles.error}>{checkboxError}</span>}
            <button
              type="submit"
              className={styles.btn__form}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? "Processing..." : "Отправить"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
