import React from "react";
import styles from "./main.module.css";
import burger from "../images/burger__menu.png";
import { FC, useMemo } from "react";
import { Link as ScrollLink } from "react-scroll";
import { slide as Menu } from "react-burger-menu";
import { data } from "../../constants/constants";
const MainPage = () => {
  const renderTitle = useMemo(
    () => data.map((item) => <a href={item.links}> {item.title}</a>),
    [data]
  );
  const CustomBurgerIcon = () => (
    <div className="burgermenu">
      <img
        style={{
          width: "32px",
        }}
        src={burger}
      />
    </div>
  );
  return (
    <div>
      <section className={styles.main}>
        <header>
          <div className={styles.container2}>
            <a href="/">
              <h6>AllWatch</h6>
            </a>
            <div className={styles.nav2}>
              <a className={styles.header__link} href="/catalog">
                Каталог
              </a>
              <a className={styles.header__link} href="/contact">
                Контакты
              </a>
              <a className={styles.header__link} href="/delivery">
                Доставка
              </a>
            </div>
            <div className={styles.Burger}>
              <Menu
                customBurgerIcon={<CustomBurgerIcon />}
                isOpen={false}
                width={"100px"}
                right
              >
                <nav>{renderTitle}</nav>
              </Menu>
            </div>
          </div>
        </header>

        <div className={styles.main}>
          <div className={styles.ramka}>
            <h2>AllWatch</h2>
            <hr />
            <p className={styles.subtitle__ramka}>Бишкек</p>
          </div>
          <p className={styles.subtitle2}>
            Откройте для себя мир, где время встречается со стилем. В All Watch
            мы предлагаем широкий выбор часов премиум-класса на любой вкус и
            случай. Ищете ли вы вечную классику или новейшие часовые технологии,
            вы найдете это здесь.
          </p>
        </div>
      </section>
    </div>
  );
};

export default MainPage;
